import React from "react";
import Colors from "../helpers/Colors";
import StudentAvatar from "./StudentAvatar";
import { Check } from "react-feather";
import { history } from "../history";
import PlayAudio from "../helpers/PlayAudio";
import { lang } from "../helpers/Dictionary";

export default class Book extends React.Component {
  onBookClick = () => {
    PlayAudio("Drop");
    history.push("/book", {
      book: this.props.book,
      eventId: this.props.eventId,
    });
  };
  render() {
    return (
      <div className="d-flex flex-column ml-1 mb-1" onClick={this.onBookClick}>
        <div
        className="book"
          style={{
            borderWidth: 1,
            borderColor: Colors.GRAY,
            borderRadius: 10,
            maxWidth: 130,
            cursor: "pointer",
            position: "relative",
            overflow: "hidden",
            boxShadow: "1px 1px 11px 0px rgba(0,0,0,0.51)"
          }}
        >
          <img
            src={this.props.book.img}
            style={{ height: 130, width: 130, objectFit: "cover" }}
            alt={lang("book_cover_title", { title: this.props?.book?.title ? `: ${this.props.book.title}` : "" })}
            loading="lazy"
          />
          {(this.props.book.read || this.props.allRead) && (
            <>
              <div
                style={{
                  backgroundColor: "rgba(114, 255, 193, 0.3)",
                  height: 130,
                  width: 130,
                  position: "absolute",
                  marginTop: -130,
                  zIndex: 20,
                }}
              >
                <Check
                  name="check"
                  family="font-awesome"
                  size={35}
                  color={Colors.GREEN}
                  style={{
                    position: "absolute",
                    bottom: 5,
                    right: 5,
                    zIndex: 25,
                  }}
                />
              </div>
            </>
          )}
        </div>
        {this.props.book.recommendedBy && (
          <div
            className="d-flex flex-row justify-content-center align-items-center mx-auto"
            style={{ marginTop: 5 }}
          >
            <StudentAvatar
              style={{
                borderRadius: 3,
                width: 30,
                height: 30,
                marginRight: 10,
              }}
            />
            <span
              className="text-center"
              style={{ fontSize: 12, color: Colors.TEXT }}
            >
              {this.props.book.recommendedBy}
            </span>
          </div>
        )}
      </div>
    );
  }
}

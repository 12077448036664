export function bookMapper(book) {
  const fileUrl = book.file_url || ""
  const urlParts = book.file_url.split(".")
  const fileType = urlParts.length > 0 ? urlParts[urlParts.length - 1] : "pdf"
  return {
    id: book._id,
    title: book.title,
    img: book.cover_thumb_medium ? book.cover_thumb_medium : book.cover_url,
    recommendedBy: book.students ? book.students[0].firstname : undefined,
    fileUrl,
    read: book.read ? true : false,
    doublePage: book.double_page,
    fileType, //TODO: variate on response file_type (???)
  }
}

export function booksMapper(books) {
  const returnBooks = []
  books.forEach((book) => {
    returnBooks.push(bookMapper(book))
  })
  return returnBooks
}

export function randomBooksMapper(booksArray) {
  try {
    const returnArray = []
    booksArray.forEach((books) => {
      returnArray.push(booksMapper(books))
    })
    // return returnArray.flat();
    return [].concat.apply([], returnArray)
  } catch (error) {
    return []
  }
}

export function bookQaMapper(qa) {
  return qa
    ? qa.map((q) => ({
        question: { question: q.question, id: q._id, correct: q.correct },
        answers: q.options.map((a, i) => ({
          answer: a,
          correct: i === q.correct,
        })),
      }))
    : []
}

export function categoriesMapper(categories) {
  const priorityCategory = "audiobooks"
  categories.sort(function (x, y) {
    return x === priorityCategory ? -1 : y === priorityCategory ? 1 : 0
  })
  return categories
}

import ReaderUtils from "../utils/ReaderUtils"

export class HighlightDataStore {
  _dataType = "IHighlightData"

  addHighlightData(data) {
    const uid = `${this._dataType}_${Date.now()}`
    data.id = uid
    localStorage.setItem(uid, JSON.stringify(data))
    return Promise.resolve(data)
  }

  updateHighlightData(data) {
    if (!data.id) {
      throw new Error("IHighlightData instance does not have an id value.")
    }

    let highlightItem = localStorage.getItem(data.id)
    if (highlightItem) {
      localStorage.setItem(data.id, JSON.stringify(data))
    } else {
      console.warn(
        `HighlightDataStore.updateHighlightDataById, no HighlightData with id ${data.id} was found.`
      )
    }
    return Promise.resolve()
  }

  deleteHighlightDataById(id) {
    let highlightItem = localStorage.getItem(id)
    if (highlightItem) {
      localStorage.removeItem(id)
    } else {
      console.warn(
        `HighlightDataStore.deleteHighlightDataById, no HighlightData with id ${id} was found.`
      )
    }
    return Promise.resolve()
  }

  fetchHighlightDataById(id) {
    const storeItem = localStorage.getItem(id)
    let data

    if (storeItem) {
      data = JSON.parse(storeItem)
    }

    return Promise.resolve(data)
  }

  deleteHighlightDataByEbookId(id) {
    let data = ReaderUtils.filterLocalStorageItemsOnTypeName(this._dataType)
    data.forEach((item) => {
      if (item.ebookId === id && item.id) {
        window.localStorage.removeItem(item.id)
      }
    })
    return Promise.resolve()
  }

  fetchHighlightDataByEbookId(id) {
    let data = ReaderUtils.filterLocalStorageItemsOnTypeName(this._dataType)
    data = data.filter((item) => {
      return item.ebookId === id
    })
    return Promise.resolve(data.length > 0 ? data : [])
  }
}

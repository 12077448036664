export default {
  FirstTimeAnimation: require('../assets/audios/hiImTori.mp3'),
  FirstTimeAnimation2: require('../assets/audios/firstTimeHome2.mp3'),
  Login: require('../assets/audios/login.mp3'),
  Drop: require('../assets/audios/Drop.mp3'),
  NotificationPractice: require('../assets/audios/notificationPractice1.mp3'),
  NotificationAssessment: require('../assets/audios/notificationAssessment.mp3'),
  Countdown: require('../assets/audios/321.mp3'),
  Reading: require('../assets/audios/reading.mp3'),
  FinishReading1: require('../assets/audios/finishReading1.mp3'),
  FinishReading2: require('../assets/audios/finishReading2.mp3'),
  Guitar: require('../assets/audios/Guitar.wav'),
  YouRock: require('../assets/audios/youRock.mp3'),
  Share: require('../assets/audios/share.mp3'),
  Jackpot: require('../assets/audios/Jackpot.mp3'),
  Wee: require('../assets/audios/Wee.mp3'),
  Yay: require('../assets/audios/yay.wav'),
  Review: require('../assets/audios/review.mp3'),
  Woohoo: require('../assets/audios/Woohoo.wav'),
  Awesome: require('../assets/audios/awesome.mp3'),
  ReadTogether: require('../assets/audios/readTogether.mp3'),
  TwoMinutes: require('../assets/audios/2minutes.mp3'),
  AvatarSelection: require('../assets/audios/AvatarSelection.wav'),
  SwitchAvatar: require('../assets/audios/switchAvatar.wav'),
  PrealphaLetterSound: require("../assets/audios/prealphaLetterSound.wav"),
  PrealphaLetterName: require("../assets/audios/prealphaLetterName.wav"),
}
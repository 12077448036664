import moment from "moment"

export function formatDate(date, format = undefined) {
  return new Date(date).toLocaleDateString("en-US")
}

export function formatDateYYYYMMDD(date) {
  return new Date(date).toISOString().substring(0, 10)
}

export const parseDate = (date, format = "MM/DD") => {
  let parsedDate = moment.utc(date).format(format)
  return parsedDate
}

export const formatTime = (time) => {
  if (time && !isNaN(time)) {
    const minutes = Math.floor(time / 60)
    const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`
    const seconds = Math.floor(time % 60)
    const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`
    return `${formatMinutes}:${formatSeconds}`
  }
  return "00:00"
}

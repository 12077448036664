import { Ebook } from "./Ebook"
import { EbookDataStore } from "../store/EbookDataStore"
import { BookmarkDataStore } from "../store/BookmarkDataStore"
import { HighlightDataStore } from "../store/HighlightDataStore"
import { ReadingPositionDataStore } from "../store/ReadingPositionDataStore"

class Bookshelf {
  constructor(
    _ebookDataStore,
    _bookmarkDataStore,
    _highlightDataStore,
    _readingPositionDataStore
  ) {
    this._ebookDataStore = _ebookDataStore
    this._bookmarkDataStore = _bookmarkDataStore
    this._highlightDataStore = _highlightDataStore
    this._readingPositionDataStore = _readingPositionDataStore
    this._bookshelfDataUpdatedCallback = () => {}
  }

  setBookshelfUpdatedCallback(callback) {
    this._bookshelfDataUpdatedCallback = callback
  }

  async fetchEbookByUri(uri) {
    const ebookDataList = await this._ebookDataStore.fetchAllEbookData()
    const ebookData = ebookDataList.filter((item) => {
      return item.uri === uri
    })

    if (!ebookData || ebookData.length < 1) {
      return Promise.resolve(undefined)
    }

    return Promise.resolve(
      new Ebook(
        ebookData[0],
        this._highlightDataStore,
        this._bookmarkDataStore,
        this._readingPositionDataStore
      )
    )
  }

  async fetchEbookByHash(hashSignature) {
    const ebookDataList = await this._ebookDataStore.fetchAllEbookData()
    const ebookData = ebookDataList.filter((item) => {
      return item.hashSignature === hashSignature
    })

    if (!ebookData || ebookData.length < 1) {
      return Promise.resolve(undefined)
    }

    return Promise.resolve(
      new Ebook(
        ebookData[0],
        this._highlightDataStore,
        this._bookmarkDataStore,
        this._readingPositionDataStore
      )
    )
  }

  async fetchEbooks() {
    let ebookDataList = []
    let ebooks = []

    ebookDataList = await this._ebookDataStore.fetchAllEbookData()

    for (let ebookData of ebookDataList) {
      ebooks.push(
        new Ebook(
          ebookData,
          this._highlightDataStore,
          this._bookmarkDataStore,
          this._readingPositionDataStore
        )
      )
    }
    return ebooks
  }

  async fetchEbook(id) {
    let ebookData
    // let ebooks

    ebookData = await this._ebookDataStore.fetchEbookDataById(id)

    if (!ebookData) {
      return undefined
    }

    return new Ebook(
      ebookData,
      this._highlightDataStore,
      this._bookmarkDataStore,
      this._readingPositionDataStore
    )
  }

  async addEbook(data) {
    const ebookData = await this._ebookDataStore.addEbookData(data)

    if (this._bookshelfDataUpdatedCallback) {
      const ebookDataList = await this._ebookDataStore.fetchAllEbookData()
      this._bookshelfDataUpdatedCallback(ebookDataList)
    }

    return new Ebook(
      ebookData,
      this._highlightDataStore,
      this._bookmarkDataStore,
      this._readingPositionDataStore
    )
  }

  async deleteEbook(id) {
    await this._highlightDataStore.deleteHighlightDataByEbookId(id)
    await this._bookmarkDataStore.deleteBookmarkDataByEbookId(id)
    await this._readingPositionDataStore.deleteReadingPositionDataByEbookId(id)
    await this._ebookDataStore.deleteEbookDataById(id)

    if (this._bookshelfDataUpdatedCallback) {
      const ebookDataList = await this._ebookDataStore.fetchAllEbookData()
      this._bookshelfDataUpdatedCallback(ebookDataList)
    }
  }
}

export const bookshelf = new Bookshelf(
  new EbookDataStore(),
  new BookmarkDataStore(),
  new HighlightDataStore(),
  new ReadingPositionDataStore()
)

window.bookshelf = bookshelf

import React from "react"
import { lang } from "../helpers/Dictionary"
// const disabledColor = '187, 187, 187';
// const color = [
//   '53, 177, 255',
//   '109, 120, 255',
//   '255, 161, 52',
//   '64, 215, 143',
//   '255, 60, 151',
// ];

const StudentAvatars = [
  require('../assets/img/studentAvatars/0.png'),
  require("../assets/img/studentAvatars/1.png"),
  require('../assets/img/studentAvatars/2.png'),
  require('../assets/img/studentAvatars/3.png'),
  require('../assets/img/studentAvatars/4.png'),
]

export default class StudentAvatar extends React.Component {
  constructor(props) {
    super(props)
    this.image =
      StudentAvatars[Math.floor(Math.random() * StudentAvatars.length)]
  }

  render() {
    return (
      <img
        src={this.props.icon || this.image}
        alt={lang("a_students_avatar")}
        {...this.props}
      />
    )
  }
}

import React from "react";
import { ChevronDown } from "react-feather";
import { Input } from "reactstrap";
import PlayAudio from "../helpers/PlayAudio";

export default class Dropdown extends React.Component {
  onChange = (e) => {
    if (this.props.onChange) {
      PlayAudio('Drop');
      this.props.onChange(e);
    }
  };
  render() {
    return (
      <div className={this.props.className} style={{ position: 'relative' }}>
        <Input style={this.props.style} name={this.props.name} type="select" value={this.props.value} onChange={this.onChange}>
          {this.props.children}
        </Input>
        <ChevronDown style={{ position: 'absolute', right: 5, top: '20%' }} />
      </div>
    );
  }
}
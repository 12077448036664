const user = JSON.parse(localStorage.getItem('user-tr-students'));
//cada reducer tiene su propio state
const initialState = {
  user: user ? user.user : {},
  token: user ? user.token : ''
}

export const login = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN": {
      return { ...state, user: action.payload.user, token: action.payload.token }
    }
    case "LOGOUT": {
      return { ...state, user: {}, token: '' }
    }
    default: {
      return state
    }
  }
}

import React from "react"
import { Home, BookOpen, Flag, LogOut, HelpCircle } from "react-feather"
import { CustomIcons } from "../components"
import { logout } from "../helpers/AuthHelper"
import { lang } from "../helpers/Dictionary"
import { history } from "../history"

const navigationConfig = [
  {
    id: "home",
    title: lang("menu_label_title_home"),
    type: "item",
    icon: (active) => <Home size={20} />,
    navLink: "/",
    action: () => {
      history.push("/", { hideWelcome: true })
      return false
    },
  },
  {
    id: "profile",
    title: lang("menu_label_title_profile"),
    type: "item",
    icon: (active) => (
      <CustomIcons
        icon={`${active ? "ToriBlue" : "ToriWhite"}`}
        size={22}
        style={{ marginRight: "1rem" }}
      />
    ),
    navLink: "/profile",
  },
  {
    id: "books",
    title: lang("menu_label_title_books"),
    type: "item",
    icon: (active) => <BookOpen size={20} />,
    navLink: "/books",
  },
  {
    id: "path",
    title: lang("menu_label_title_path"),
    type: "item",
    icon: (active) => <Flag size={20} />,
    navLink: "/path",
  },
  {
    id: "shop",
    title: lang("menu_label_title_shop"),
    type: "item",
    icon: (active) => (
      <CustomIcons
        icon={`${active ? "ShirtBlue" : "ShirtWhite"}`}
        size={22}
        style={{ marginRight: "1rem" }}
      />
    ),
    // icon:(active)=> <ShoppingCart size={20} />,
    navLink: "/shop",
  },
  {
    id: "logout",
    title: lang("menu_label_title_logout"),
    type: "external-link",
    icon: (active) => <LogOut size={20} />,
    action: () => {
      // eslint-disable-next-line
      if (confirm(lang("are_you_sure_logout"))) {
        logout()
        return true
      }
      return false
    },
  },
  {
    id: "help",
    title: lang("need_any_help"),
    type: "external-link",
    icon: (active) => <HelpCircle size={20} />,
    action: (context) => {
      context.tutorialModalVisible(true, context.state.afterModalClose)
      return false
    },
  },
]

export default navigationConfig

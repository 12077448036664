import React, { Suspense, lazy } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { Layout } from "./utility/context/Layout"
import * as serviceWorker from "./serviceWorker"
import { store } from "./network/redux/storeConfig/store"
import Spinner from "./components/@vuexy/spinner/Fallback-spinner"
import "./index.scss"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import { getUser } from "./helpers/AuthHelper"
import { version } from "../package.json"

const getUserLocal = () => {
  const user = getUser()
  return user ? JSON.parse(user)?.student : undefined
}

const user = getUserLocal()

Bugsnag.start({
  apiKey: "316ba9023ec37a9cab75a3a97699a536",
  plugins: [new BugsnagPluginReact()],
  appVersion: version,
  releaseStage: window.location.host.includes('localhost:') ? "localhost" : window.location.host.includes('.dev.') ? "dev" : "prod",
  user: {
    id: user?.id,
    name: user?.name,
    email: user?.email,
  }
})
console.log("App version:", version)

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React)

const LazyApp = lazy(() => import("./App"))

// configureDatabase()
ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <Layout>
          <LazyApp />
        </Layout>
      </Suspense>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import React from "react"

export default function StepsBar({ totalSteps = 4, currentStepIndex = 1 }) {
  return totalSteps ? (
    <ul className="progressbar">
      {Array.from(Array(totalSteps)).map((_, i) => (
        <li
          key={i}
          className={`${
            i === currentStepIndex
              ? "current"
              : i <= currentStepIndex
              ? "active"
              : ""
          }`}
        />
      ))}
    </ul>
  ) : null
}

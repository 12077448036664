import Audios from "./Audios"
import { logError } from "./ErrorHelper"

export default (url, playDelay = 0, callback = () => {}) => {
  try {
    const audio = new Audio(Audios[url] || url)
    audio.onended = function () {
      callback()
    }
    return setTimeout(async () => {
      try {
        await audio.play()
      } catch (e) {
        logError(e, 2)
        callback()
      }
    }, playDelay)
  } catch (error) {
    logError(error, 2)
    callback()
  }
}

// export function TextToSpeech() {
//   const audioCtx = new (window.AudioContext || window.webkitAudioContext)()

//   // Create an empty three-second stereo buffer at the sample rate of the AudioContext
//   const myArrayBuffer = audioCtx.createBuffer(
//     2,
//     audioCtx.sampleRate * 3,
//     audioCtx.sampleRate
//   )

//   // Fill the buffer with white noise;
//   // just random values between -1.0 and 1.0
//   for (let channel = 0; channel < myArrayBuffer.numberOfChannels; channel++) {
//     // This gives us the actual array that contains the data
//     const nowBuffering = myArrayBuffer.getChannelData(channel)
//     for (let i = 0; i < myArrayBuffer.length; i++) {
//       // Math.random() is in [0; 1.0]
//       // audio needs to be in [-1.0; 1.0]
//       nowBuffering[i] = Math.random() * 2 - 1
//     }
//   }

//   // Get an AudioBufferSourceNode.
//   // This is the AudioNode to use when we want to play an AudioBuffer
//   const source = audioCtx.createBufferSource()

//   // set the buffer in the AudioBufferSourceNode
//   source.buffer = myArrayBuffer

//   // connect the AudioBufferSourceNode to the
//   // destination so we can hear the sound
//   source.connect(audioCtx.destination)

//   // start the source playing
//   source.start()
// }

import { SyncMediaFormat } from "@colibrio/colibrio-reader-framework/colibrio-readingsystem-base"

export default class AppMediaOverlayControls {
  constructor(
    syncMediaPlayer,
    readerView,
    playButton,
    previousButton,
    nextButton,
    playbackPositionContainer,
    readyStatusContainer
  ) {
    this.syncMediaPlayer = syncMediaPlayer
    this.readerView = readerView
    this.playButton = playButton
    this.previousButton = previousButton
    this.nextButton = nextButton
    this.playbackPositionContainer = playbackPositionContainer
    this.readyStatusContainer = readyStatusContainer

    // To synchronize playback with the content visible in the ReaderView, attach the sync media player to the ReaderView.
    readerView.setSyncMediaPlayer(syncMediaPlayer)
    // To further configure how the syncMediaPlayer synchronizes with the ReaderView, see the methods:
    // - syncMediaPlayer.setManagedReaderViewSynchronizationOptions()
    // - syncMediaPlayer.setReaderViewSynchronizationWaitBehavior()

    // Colibrio Engine Event listeners for the sync media player events.
    syncMediaPlayer.addEngineEventListener(
      "syncMediaPlay",
      this.handlePlayEvent
    )
    syncMediaPlayer.addEngineEventListener(
      "syncMediaPaused",
      this.handlePauseEvent
    )
    syncMediaPlayer.addEngineEventListener(
      "syncMediaReady",
      this.handleReadyEvent
    )
    syncMediaPlayer.addEngineEventListener(
      "syncMediaError",
      this.handleErrorEvent
    )
    syncMediaPlayer.addEngineEventListener(
      "syncMediaWaiting",
      this.handleWaitingEvent
    )
    syncMediaPlayer.addEngineEventListener(
      "syncMediaTimelinePositionChanged",
      this.handlePositionChangedEvent
    )
    syncMediaPlayer.addEngineEventListener(
      "syncMediaSegmentDurationChanged",
      this.handleDurationChangedEvent
    )

    // Set up the buttons for media controls
    playButton.addEventListener("click", this.handlePlayPauseButtonClicked)
    nextButton.addEventListener("click", this.handleNextButtonClicked)
    previousButton.addEventListener("click", this.handlePreviousButtonClicked)
    playButton.removeAttribute("disabled")
    nextButton.removeAttribute("disabled")
    previousButton.removeAttribute("disabled")

    this.updateStatusText()
  }

  static async create(readerView, epubReaderPublication, container) {
    const playButton = container.querySelector("#play-pause-button")
    const previousButton = container.querySelector("#previous-segment-button")
    const nextButton = container.querySelector("#next-segment-button")
    const playbackPositionContainer = container.querySelector(
      "#media-overlay-playback-position"
    )
    const readyStatusContainer = container.querySelector(
      "#media-overlay-status"
    )

    if (
      playButton &&
      previousButton &&
      nextButton &&
      playbackPositionContainer &&
      readyStatusContainer
    ) {
      const isMediaOverlaySupported = epubReaderPublication
        .getAvailableSyncMediaFormats()
        .includes(SyncMediaFormat.EPUB_MEDIA_OVERLAY)
      if (isMediaOverlaySupported) {
        const loadingProgressCallback = (progress) =>
          (readyStatusContainer.textContent =
            progress * 100 + "% of media overlay loaded")
        const timelineOptions = { name: "EpubMediaOverlayTimeline" }
        const timeline =
          await epubReaderPublication.createMediaOverlaySyncMediaTimeline(
            epubReaderPublication.getSpine(),
            timelineOptions,
            loadingProgressCallback
          )

        const syncMediaPlayer = readerView
          .getReadingSystemEngine()
          .createSyncMediaPlayer(timeline)
        return new AppMediaOverlayControls(
          syncMediaPlayer,
          readerView,
          playButton,
          previousButton,
          nextButton,
          playbackPositionContainer,
          readyStatusContainer
        )
      } else {
        return Promise.reject("The publication does not support Media Overlay")
      }
    } else {
      return Promise.reject("The required HTML elements are missing!")
    }
  }

  destroy = () => {
    this.readerView.setSyncMediaPlayer(null)

    // Destroying the SyncMediaPlayer will also remove any event listeners attached to it.
    this.readerView
      .getReadingSystemEngine()
      .destroySyncMediaPlayer(this.syncMediaPlayer)

    this.disableButton(this.previousButton)
    this.disableButton(this.nextButton)
    this.disableButton(this.playButton)
  }

  handlePlayPauseButtonClicked = () => {
    if (this.syncMediaPlayer.isPaused()) {
      this.syncMediaPlayer.play()
    } else {
      this.syncMediaPlayer.pause()
    }
  }

  handleNextButtonClicked = () => {
    this.syncMediaPlayer.seekToNextSegment()
    this.enableDisableButtons()
  }

  handlePreviousButtonClicked = () => {
    this.syncMediaPlayer.seekToPreviousSegment()
    this.enableDisableButtons()
  }

  disableButton = (button) => {
    button.setAttribute("disabled", "")
  }

  enableButton = (button) => {
    button.removeAttribute("disabled")
  }

  handleDurationChangedEvent = () => {
    this.updateStatusText()
  }

  handlePlayEvent = () => {
    this.playButton.setAttribute("data-isplaying", "true")
  }

  handlePauseEvent = () => {
    this.playButton.removeAttribute("data-isplaying")
  }

  handleReadyEvent = () =>
    (this.readyStatusContainer.textContent = "Ready to play")

  handleErrorEvent = (evt) => {
    console.error(
      "SyncMediaPlayer error: " + evt.errorType + " - " + evt.errorMessage
    )
    this.readyStatusContainer.textContent = "Error playing media overlay!"
  }

  handleWaitingEvent = (evt) => {
    if (evt.waitingForReaderViewSynchronization) {
      this.readyStatusContainer.textContent =
        "Waiting for synchronization with ReaderView"
    } else if (evt.waitingForMediaObjectRenderers) {
      this.readyStatusContainer.textContent = "Loading media"
    }
  }

  handlePositionChangedEvent = () => {
    this.updateStatusText()
    this.enableDisableButtons()
  }

  updateStatusText = () => {
    const elapsedSeconds = this.formatDuration(
      this.syncMediaPlayer.getApproximateElapsedTimeMs()
    )
    const totalDuration = this.formatDuration(
      this.syncMediaPlayer.getTimeline().getApproximateDurationMs()
    )
    this.playbackPositionContainer.innerText =
      "Elapsed " + elapsedSeconds + " / " + totalDuration
  }

  enableDisableButtons = () => {
    if (this.syncMediaPlayer.isAtStart()) {
      this.disableButton(this.previousButton)
    } else {
      this.enableButton(this.previousButton)
    }
    if (this.syncMediaPlayer.isAtEnd()) {
      this.disableButton(this.nextButton)
    } else {
      this.enableButton(this.nextButton)
    }
  }

  formatDuration = (milliSeconds) => {
    const seconds = milliSeconds / 1000
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = Math.floor(seconds % 60)

    return (
      (hours > 0 ? hours + "h" : "") +
      (minutes > 0 ? minutes + "m" : "") +
      remainingSeconds +
      "s"
    )
  }
}

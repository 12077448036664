export class Ebook {
  constructor(
    _ebookData,
    _highlightDataStore,
    _bookmarkDataStore,
    _readingPositionHistoryStore
  ) {
    this._ebookData = _ebookData
    this._highlightDataStore = _highlightDataStore
    this._bookmarkDataStore = _bookmarkDataStore
    this._readingPositionHistoryStore = _readingPositionHistoryStore
    
    if (!this._ebookData.id) {
      throw new Error("IEbookData instance has no id value.")
    }

    this._id = this._ebookData.id
    this._uri = this._ebookData.uri
    this._title = this._ebookData.title
    this._coverImage = this._ebookData.coverImage
    this._author = this._ebookData.author
    this._dateCreated = this._ebookData.dateCreated
    this._metadata = this._ebookData.metadata || undefined
  }

  get id() {
    if (!this._id) {
      throw new Error("IEbookData does not have an id value.")
    }

    return this._id
  }

  get uri() {
    return this._uri
  }

  get title() {
    return this._title
  }

  get author() {
    return this._author
  }

  get coverImage() {
    return this._coverImage
  }

  setHighlightDataUpdatedCallback = (callback) => {
    this._highlightDataUpdatedCallback = callback
  }

  setBookmarkDataUpdatedCallback = (callback) => {
    this._bookmarkDataUpdatedCallback = callback
  }

  setReadingPositionDataUpdatedCallback = (callback) => {
    this._readingPositionDataUpdatedCallback = callback
  }

  async fetchBookmarks() {
    return this._bookmarkDataStore.fetchBookmarkDataByEbookId(this.id)
  }

  async fetchHighlights() {
    return this._highlightDataStore.fetchHighlightDataByEbookId(this.id)
  }

  async fetchReadingPositionHistory() {
    return this._readingPositionHistoryStore.fetchReadingPositionDataByEbookId(
      this.id
    )
  }

  async addBookmark(locator, sectionTitle = undefined) {
    const bookmarkData = await this._bookmarkDataStore.addBookmarkData({
      ebookId: this.id,
      sectionTitle,
      locator: locator,
      dateCreated: Date.now(),
    })

    if (this._bookmarkDataUpdatedCallback) {
      this._bookmarkDataUpdatedCallback(
        (await this._bookmarkDataStore.fetchBookmarkDataByEbookId(this.id)) ||
          []
      )
    }

    return bookmarkData
  }

  async removeBookmark(id) {
    this._bookmarkDataStore.deleteBookmarkDataById(id).catch(console.warn)

    if (this._bookmarkDataUpdatedCallback) {
      this._bookmarkDataUpdatedCallback(
        (await this._bookmarkDataStore.fetchBookmarkDataByEbookId(this.id)) ||
          []
      )
    }
  }

  async addHighlight(locator, highlightColor, note, sectionTitle = undefined) {
    const highlightData = await this._highlightDataStore.addHighlightData({
      ebookId: this.id,
      note,
      locator,
      sectionTitle,
      dateCreated: Date.now(),
      highlightColor,
      dateLastUpdated: Date.now(),
    })

    if (this._highlightDataUpdatedCallback) {
      this._highlightDataUpdatedCallback(
        (await this._highlightDataStore.fetchHighlightDataByEbookId(this.id)) ||
          []
      )
    }

    return highlightData
  }

  async removeHighlight(id) {
    this._highlightDataStore.deleteHighlightDataById(id).catch(console.warn)

    if (this._highlightDataUpdatedCallback) {
      this._highlightDataUpdatedCallback(
        (await this._highlightDataStore.fetchHighlightDataByEbookId(this.id)) ||
          []
      )
    }
  }

  async updateHighlight(id, locator, highlightColor, note) {
    if (!id) {
      throw new Error("IHighlightData has no id value.")
    }

    await this._highlightDataStore.updateHighlightData({
      id,
      ebookId: this.id,
      locator,
      highlightColor,
      note,
      dateCreated: this._dateCreated,
    })

    if (this._highlightDataUpdatedCallback) {
      this._highlightDataUpdatedCallback(
        (await this._highlightDataStore.fetchHighlightDataByEbookId(this.id)) ||
          []
      )
    }
  }

  async addReadingPosition(locator, timelinePosition) {
    await this._readingPositionHistoryStore.deleteAllReadingPositionDataByEbookId(
      this.id
    )

    const readingPositionData =
      await this._readingPositionHistoryStore.addReadingPositionData({
        ebookId: this.id,
        locator,
        dateCreated: Date.now(),
        timelinePosition,
      })

    if (this._readingPositionDataUpdatedCallback) {
      this._readingPositionDataUpdatedCallback(readingPositionData)
    }

    return readingPositionData
  }

  serialize() {
    return {
      id: this.id,
      uri: this._uri,
      title: this._title,
      author: this._author,
      coverImage: this._coverImage,
      dateCreated: this._dateCreated,
      dateLastUpdated: this._dateLastUpdated,
      metadata: this._metadata,
    }
  }
}

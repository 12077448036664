import { logError } from "../../helpers/ErrorHelper"
import { history } from "../../history"
export const errorHandler = (error) => {
  if (error?.response) {
    // console.error("Error handler", error.response);
    logError(error, error.response.status === 422 ? 0 : 2)
    return false
  } else {
    logError(error, 2)
    // console.error("Error handler", error);
    history.push("/500")
    return true //Return if error was already handled
  }
}

export function pointsResponseMapper(points) {
  const questions = points.questions ? points.questions.map(q => { return { question: q.question, correct: (q.student_answer === q.correct_answer) } }) : [];
  return {
    finalPoints: points.final_points,
    added: (points.added ? { qty: points.added.qty, concept: points.added.concept } : null),
    level: (points.level ? { actualLvl: points.level.actual_lvl, nextLvl: points.level.next_level , points: points.level.points} : null),
    questions,
    correctPercent: (questions.length > 0 ? ((questions.filter(q => q.correct === true).length * 100) / questions.length) : 0).toFixed(0),
    coins: points.coins
  }
}
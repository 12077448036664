import Provider from "./Provider"
import { errorHandler } from "./errorHandler"
import {
  bookQaMapper,
  booksMapper,
  categoriesMapper,
  randomBooksMapper,
} from "../mappers/booksMappers"
import { pointsResponseMapper } from "../mappers/pointsMappers"

export const getBooksCategories = () => {
  return new Promise((resolve, reject) => {
    Provider.get("/books-keywords")
      .then((response) => {
        resolve(categoriesMapper(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getStudentBooks = () => {
  return new Promise((resolve, reject) => {
    Provider.get("/student-books")
      .then((response) => {
        resolve(booksMapper(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getUnfinishedBooks = () => {
  return new Promise((resolve, reject) => {
    // Provider.get("/student-books")
    Provider.get("/unfinished-student-books")
      .then((response) => {
        resolve(booksMapper(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getRecomendedBooks = () => {
  return new Promise((resolve, reject) => {
    Provider.get("/recomended-books")
      .then((response) => {
        resolve(booksMapper(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getNewBooks = () => {
  return new Promise((resolve, reject) => {
    Provider.get("/new-books")
      .then((response) => {
        resolve(booksMapper(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getRandomBooks = () => {
  return new Promise((resolve, reject) => {
    Provider.get("/random-books")
      .then((response) => {
        resolve(randomBooksMapper(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getSearchBook = (text, min_lexile, max_lexile) => {
  return new Promise((resolve, reject) => {
    Provider.get(`/search-book/${text}/${min_lexile}/${max_lexile}`)
      .then((response) => {
        resolve(booksMapper(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getBooksByCategory = (category) => {
  return new Promise((resolve, reject) => {
    Provider.get(`/books-by-keyword/${category}`)
      .then((response) => {
        resolve(booksMapper(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const readBook = (book_id, event_id, answers, qa_time, reading_time) => {
  return new Promise((resolve, reject) => {
    const DTO = {
      book_id,
      event_id: event_id ? event_id : null,
      answers,
      qa_time,
      reading_time,
    }
    Provider.post("/post-reading", DTO)
      .then((response) => resolve(pointsResponseMapper(response)))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const reviewBook = (id, review, event_id) => {
  return new Promise((resolve, reject) => {
    Provider.put(`/book/review/${id}`, { review, event_id })
      .then(() => resolve())
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const shareBook = (book_id, friends) => {
  return new Promise((resolve, reject) => {
    const DTO = { book_id, friends }
    Provider.post("/share-book", DTO)
      .then(() => resolve())
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const trackTime = (
  book_id = null,
  event_id = null,
) => {
  return new Promise((resolve, reject) => {
    Provider.put("/practice-time", { book_id, event_id })
      .then(() => resolve())
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getBookQa = (book_id) => {
  return new Promise((resolve, reject) => {
    Provider.get(`/get-book-qa/${book_id}`)
      .then((response) => {
        resolve(bookQaMapper(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getShowBookQa = (book_id) => {
  return new Promise((resolve, reject) => {
    Provider.get(`/show-book-qa/${book_id}`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const textToSpeechPolly = (
  text,
  book_qa_id,
  is_question = false,
  option_index = undefined
) => {
  const DTO = { text, book_qa_id, is_question, option_index }
  return new Promise((resolve, reject) => {
    Provider.post("/text-to-speech-polly", DTO)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export class EbookDataStore {
  _dataType = "IEbookData"

  fetchEbookDataById(id) {
    const storeItem = localStorage.getItem(id)
    let data

    if (storeItem) {
      data = JSON.parse(storeItem)
    }

    return Promise.resolve(data)
  }

  updateEbookData(ebookData) {
    return Promise.resolve(undefined)
  }

  deleteEbookDataById(id) {
    let item = localStorage.getItem(id)
    if (item) {
      localStorage.removeItem(id)
    } else {
      console.warn(
        `EbookDataStore.deleteEbookDataById, no IEbookData with id ${id} was found.`
      )
    }
    return Promise.resolve()
  }

  addEbookData(data) {
    const uid = `${this._dataType}_${Date.now()}`
    data.id = uid
    // localStorage.setItem(uid, JSON.stringify(data))
    return Promise.resolve(data)
  }

  fetchAllEbookData() {
    let ebookData = []

    for (let i = 0; i < localStorage.length; i++) {
      let itemKey = localStorage.key(i)
      if (itemKey && itemKey.startsWith(this._dataType)) {
        const itemValue = localStorage.getItem(itemKey)
        if (itemValue) {
          const ebookDataItem = JSON.parse(itemValue)
          ebookData.push(ebookDataItem)
        }
      }
    }
    return Promise.resolve(ebookData)
  }
}

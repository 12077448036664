import React from "react"
import { Row } from "reactstrap"
import Colors from "../helpers/Colors"

export default class ColoredBar extends React.Component {
  render() {
    return (
      <Row
        style={{
          ...{
            height: 16,
            width: "100%",
            backgroundColor: Colors.SEPARATOR,
            borderRadius: 100,
            overflow: "hidden",
            position: "relative",
            alignItems: "center",
          },
          ...this.props.style,
        }}
      >
        <div
          style={{
            backgroundColor: this.props.color,
            opacity: 0.2,
            width: `100%`,
            borderRadius: 0,
            height: 16,
          }}
        />
        <div
          style={{
            backgroundColor: this.props.color,
            width: `${this.props.size * 100}%`,
            borderRadius: 100,
            height: 16,
            position: "absolute",
          }}
        />
      </Row>
    )
  }
}

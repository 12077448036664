import React, { useState } from "react"
import { Input } from "reactstrap"
import Colors from "../helpers/Colors"
import { Search, X } from "react-feather"
import { lang } from "../helpers/Dictionary"

export default function Searchbar({
  btnClick = () => {},
  searchActive = false,
}) {
  const [text, setText] = useState("")
  const search = () => {
    if (searchActive) setText("")
    btnClick(searchActive ? "" : text)
  }
  return (
    <div
      className="d-flex flex-row align-items-center"
      style={{
        width: window.innerWidth >= 992 ? "60%" : "100%",
        backgroundColor: Colors.LIGHTGRAY,
        borderRadius: "2rem",
      }}
      >
      <Input
        style={{
          backgroundColor: Colors.LIGHTGRAY,
          borderRadius: "2rem 0px 0px 2rem",
          borderColor: "transparent",
          boxShadow: "none",
        }}
        placeholder={lang("search_books")}
        value={text}
        onChange={(e) => {
          setText(e.target.value)
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            search()
          }
        }}
        />
      <div
        style={{
          backgroundColor: Colors.LIGHTGRAY,
          borderRadius: "0px 2rem 2rem 0px",
          borderColor: "transparent",
          cursor: text ? "pointer" : "default",
          height: "100%",
          display: "flex",
          padding: "0.7rem 1rem 0.7rem 0.7rem",
        }}
        onClick={search}
      >
        {searchActive ? (
          <X size={"0.96rem"} />
        ) : (
          <Search
            size={"0.96rem"}
            color={text ? "#000" : Colors.ACHIEVEMENTS_GRAY}
          />
        )}
      </div>
    </div>
  )
}

import Bugsnag from "@bugsnag/js"
import { history } from "../history"
import {
  loginUserAction,
  logoutUserAction,
} from "../network/redux/actions/auth/loginActions"
import { store } from "../network/redux/storeConfig/store"

export const ENTITIES = {
  STUDENT: "students",
  PARENT: "parents",
}

export const userKey = (entity = ENTITIES.STUDENT) => {
  return `user-tr-${entity}${
    window.location.host.includes("localhost:")
      ? "-localhost"
      : window.location.host.includes(".dev.")
      ? "-dev"
      : ""
  }`
}

export const login = (auth) => {
  Bugsnag.setUser(auth?.student?.id, auth?.student?.email, auth?.student?.name)
  store.dispatch(loginUserAction(auth))
  localStorage.setItem(userKey(), JSON.stringify(auth))
  // history.push('/',{hideWelcome: true});
  window.location.href = "/" // Refresh page on enter
}

export const logout = () => {
  store.dispatch(logoutUserAction())
  localStorage.removeItem(userKey())
  localStorage.removeItem(userKey(ENTITIES.PARENT))
  Bugsnag.setUser(null)
  history.push("/login")
}

export const getUser = (entity = ENTITIES.STUDENT) =>
  localStorage.getItem(userKey(entity))

export const getToken = () => {
  let auth = getUser()
  return auth ? JSON.parse(auth).token : undefined
}

export const updateCoins = (student) => {
  let user = JSON.parse(getUser())
  user.student = student
  localStorage.setItem(userKey(), JSON.stringify(user))
  return user
}

export const firstTimeAnimation = () => {
  let user = JSON.parse(getUser())
  return user?.firstTimeAnimation
}

export const updateFirstTimeAnimation = () => {
  let user = getUser()
  if (user) {
    user = JSON.parse(user)
    user.firstTimeAnimation = true
    localStorage.setItem(userKey(), JSON.stringify(user))
  }
  return user
}

export const updateUserAvatar = async (userResponse) => {
  let user = JSON.parse(getUser())
  if (
    userResponse &&
    userResponse.active_avatar &&
    userResponse.available_avatars &&
    (userResponse.coins !== undefined || userResponse.coins !== null)
  ) {
    user.student.avatar = {
      skin: {
        id: userResponse.active_avatar?.active_skin_id,
        url: userResponse.active_avatar?.active_skin,
        lottie: userResponse.active_avatar?.active_lottie,
      },
      icon: {
        id: userResponse.active_avatar?.active_icon_id,
        url: userResponse.active_avatar?.active_icon,
      },
      available: userResponse.available_avatars
        ? userResponse.available_avatars
        : [],
    }
    user.student.coins = userResponse.coins
    localStorage.setItem(userKey(), JSON.stringify(user))
  }
  return user.student
}

export const parentLogin = async (auth, callback = () => {}) => {
  localStorage.setItem(userKey(ENTITIES.PARENT), JSON.stringify(auth))
  callback()
}

export const parentLogout = async (callback) => {
  localStorage.removeItem(userKey())
  localStorage.removeItem(userKey(ENTITIES.PARENT))
  callback()
}

const generateSessionDeviceToken = (token) => {
  let date = new Date().getTime().toString(36) + new Date().getUTCMilliseconds()
  return date + token.substr(-4) + Math.random().toString(36).substr(2)
}

export const getOrUpdateSessionDeviceToken = () => {
  let auth = getUser()
  if (auth) {
    auth = JSON.parse(auth)
    if (!auth.session_device) {
      auth.session_device = generateSessionDeviceToken(auth.token)
      localStorage.setItem(userKey(), JSON.stringify(auth))
    }
    return auth.session_device
  }
}

import React from "react"

export default function FlipCard({
  containerStyle = {},
  front = undefined,
  back = undefined,
  active = "front",
}) {
  return (
    <div className="flip-container" style={containerStyle}>
      <div className={`flipper ${active}`}>
        <div className="front">{front}</div>
        <div className="back">{back}</div>
      </div>
    </div>
  )
}

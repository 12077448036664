import React from 'react';
import { lang } from '../helpers/Dictionary';

export default class AvatarSkin extends React.Component {
  changeSrc(src) {
    const player = document.querySelector("lottie-player");
    if (player) player.load(src);
  }
  render() {
    const { lottie, url, style, ...rest } = this.props;
    return lottie ?
      <lottie-player id='lottie' src={lottie} style={style} loop autoplay {...rest} />
      :
      <img src={url} style={style} alt={lang('avatar')} {...rest} />
  }
}
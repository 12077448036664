import Bugsnag from "@bugsnag/js"
import { lang, exists } from "./Dictionary"

export const logError = (e, severity = 0) => {
  //TODO: Maybe check for user
  const ErrorSeverity = ["info", "warning", "error"]
  if (severity === 0) {
    console.log("logError", ErrorSeverity[severity], e)
  } else if (severity === 1) {
    console.log("logError", ErrorSeverity[severity], e)
  } else if (severity === 2) {
    console.log("logError", ErrorSeverity[severity], e)
  }
  Bugsnag.notify(e, (event) => {
    event.severity = ErrorSeverity[severity]
  })
}

export const parseError = (e) => {
  console.log(e)
  let toLang =
    e?.response?.data?.message ||
    e?.response?.data?.errors?.[0]?.msg ||
    "unknown_error"
    console.log("toLang", toLang)
    console.log("lang", lang(toLang))
  try {
    let field = undefined
    if (toLang.includes("|")) {
      ;[toLang, field] = toLang.split("|")
      if (exists(field)) field = lang(field)
    }
    const response = lang(toLang, { field })
    logError(`Lang error (toLang: ${toLang}, field: ${field}) => ${response}`)
    return response
  } catch (error) {
    logError(error, 1)
    return  toLang || ""
  }
}

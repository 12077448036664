import React, { useEffect, useMemo, useRef, useState } from "react"
import { logError } from "../../helpers/ErrorHelper"
import { ReadingSystemAPI } from "./ReadingSystemAPI"
import { KeyCode } from "@colibrio/colibrio-reader-framework/colibrio-core-base"
import NavigationView from "./NavigationView"
import AppMediaOverlayControls from "./AppMediaOverlayControls"
import { ReadAloudMenu } from "./ReadAloudMenu"
import axios from "axios"
import { API_URL } from "../../config"
import { Spinner } from "reactstrap"
import { BOOK_FILE_TYPES } from "../../helpers/Constants"

let appMediaOverlayControl = undefined

export function ColibrioView({
  url,
  componentPrevPage,
  componentNextPage,
  onLoad = () => {},
  isEpub,
  isMobile = false,
  isDoublePage = false,
}) {
  const viewRef = useRef(null)
  let [publicationLoaded, setPublicationLoaded] = useState(false)
  let [timelineLength, setTimelineLength] = useState(0)
  let [readingPositionData, setReadingPositionData] = useState(undefined)

  const readingSystemInstance = useMemo(() => {
    return ReadingSystemAPI.createInstance(
      process.env.REACT_APP_COLIBRIO_LICENSE_API_KEY,
      isDoublePage
    )
    // eslint-disable-next-line
  }, [])

  const onPublicationLoaded = async (publication) => {
    if (publication) {
      setTimelineLength(
        readingSystemInstance.contentPositionTimelineLength || 0
      )
      readingSystemInstance.setReadingProgressCallback(setReadingPositionData)
      readingSystemInstance.setKeyUpCallback(onKeyUp)
      // readingSystemInstance.setPointerUpCallback(onPointerUp)
      // readingSystemInstance.setKeyDownCallback(onKeyDown)
      // readingSystemInstance.setSelectionChangeCallback(onSelectionChange)
      // readingSystemInstance.setPointerDownCallback(onPointerDown)
      // readingSystemInstance.setNavigationIntentCallback(onNavigationIntent)
      if (isEpub) {
        const mediaOverlay = await AppMediaOverlayControls.create(
          readingSystemInstance.readerView,
          publication,
          document.getElementById("media-overlay-controls-container")
        ).catch((reason) => {
          logError(reason, 2)
          console.log("ERROR ON APPMEDIAOVELAR")
          return undefined
        })
        appMediaOverlayControl = mediaOverlay
      }
      setPublicationLoaded(true)
      onLoad(readingSystemInstance.contentPositionTimelineLength || 1)
    }
  }

  // TO LOAD FROM LOCAL FILE
  // const hiddenFileInput = useRef(null)
  // const handleFileChange = async (files) => {
  //   if (!files || files.length < 1) {
  //     return
  //   }
  //   const bookFile = files[0]
  //   const ebook = await readingSystemInstance
  //     .loadPublicationByFile(bookFile)
  //     .catch((error) => {
  //       console.error(error)
  //     })
  //   if (!ebook) {
  //     return
  //   }
  //   onPublicationLoaded(ebook)
  // }

  useEffect(() => {
    readingSystemInstance
      .loadPublicationByUrl(url)
      .then((publication) => {
        window.addEventListener("resize", onViewRefresh)
        window.addEventListener("keyup", onKeyUp)
        onPublicationLoaded(publication)
      })
      .catch((error) => {
        logError(error, 2)
      })
    if (componentPrevPage !== undefined) {
      componentPrevPage(onNavigatePrevious)
    }
    if (componentNextPage !== undefined) {
      componentNextPage(onNavigateNext)
    }
    return () => {
      if (readingSystemInstance) {
        readingSystemInstance.renderToElement(null)
        readingSystemInstance.readerView.setReaderDocuments([])
        if (appMediaOverlayControl) {
          appMediaOverlayControl.destroy()
        }
      }
      window.removeEventListener("resize", onViewRefresh)
      window.removeEventListener("keyup", onKeyUp)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (viewRef && viewRef.current) {
      readingSystemInstance.renderToElement(viewRef.current)
      // onRendered()
    }
    // eslint-disable-next-line
  }, [publicationLoaded])

  const onNavigateNext = () => {
    readingSystemInstance.next()
  }

  const onNavigatePrevious = () => {
    readingSystemInstance.previous()
  }

  const onGotoTimelinePosition = async (position) => {
    const contentLocation =
      await readingSystemInstance.fetchContentLocationFromContentPositionTimelinePosition(
        position
      )
    if (!contentLocation) {
      console.warn(
        "ReaderApp.onGotoTimelinePosition(), Unable to fetch ContentLocation for timeline position"
      )
      return
    }
    readingSystemInstance.goto(contentLocation.getLocator()).catch(console.warn)
  }

  const onKeyUp = (event) => {
    switch (event.keyCode) {
      case KeyCode.KEY_LEFT:
        onNavigatePrevious()
        break
      case KeyCode.KEY_RIGHT:
        onNavigateNext()
        break
      default:
        break
    }
  }

  // const onSelectionChange = (event) => {
  //   if (event.isRange) {
  //     setTextSelected(true)
  //     activeSelectionLocator = event.contentLocation?.getLocator()
  //   } else {
  //     setTextSelected(false)
  //     activeSelectionLocator = undefined
  //   }
  // }
  // const onNavigationIntent = (event) => {
  //   closeAllOpenDialogs()
  // }
  // const onPointerUp = (event) => {
  //   let locator = event.target?.contentLocation?.getLocator()
  //   if (locator && !readingSystemInstance.isNavigating) {
  //     readingSystemInstance.goto(locator).catch(console.warn)
  //   }
  // }
  // const onPointerDown = (event) => {}
  // const onKeyDown = (event) => {}
  // const onRendered = () => {}
  // const closeAllOpenDialogs = () => {}

  const onViewRefresh = () => {
    readingSystemInstance.refreshReaderView()
  }

  return (
    <div
      className={`d-flex flex-column${isMobile ? "-reverse" : ""}`}
      style={{
        height: isMobile ? "100vh" : "100%",
        maxHeight: "100vh",
        justifyContent: "space-between",
      }}
    >
      <NavigationView
        isMobile={isMobile}
        timelineLength={timelineLength}
        timelinePosition={readingPositionData?.timelinePosition}
        onSliderPositionChange={onGotoTimelinePosition}
        onNavigatePrevious={onNavigatePrevious}
        onNavigateNext={onNavigateNext}
        previousBtnEnabled={readingSystemInstance?.canPerformPrevious}
        nextBtnEnabled={readingSystemInstance?.canPerformNext}
      />
      <div
        id="reader-view"
        style={{
          flex: 1,
          minHeight: "30vh",
          height: "100%",
          maxHeight: window.innerWidth,
          width: "100%",
          position: "relative",
        }}
        ref={viewRef}
      />
      <div>
        {isEpub ? (
          <div
            style={{
              width: "fit-content",
              margin: "0 auto",
              paddingTop: "0.5rem",
            }}
          >
            <ReadAloudMenu isMobile={isMobile} />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export function MobileColibrioView({ bookId, token }) {
  const [book, setBook] = useState(undefined)
  useEffect(() => {
    if (bookId && token && book === undefined) {
      const provider = axios.create({ baseURL: API_URL })
      provider.defaults.headers.common["Authorization"] = `Bearer ${token}`
      provider
        .get(`${API_URL}/get-book/${bookId}`)
        .then((response) => {
          setBook(response?.data?.data)
        })
        .catch((e) => {
          logError(e)
        })
    }
    // eslint-disable-next-line
  }, [])

  return book ? (
    <ColibrioView
      isMobile
      url={book?.file_url}
      isEpub={book?.format_type === BOOK_FILE_TYPES.EPUB}
      isDoublePage={book?.double_page}
    />
  ) : (
    <div
      className="d-flex flex-row justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <Spinner color="primary" style={{ height: 50, width: 50, margin: 5 }} />
    </div>
  )
}

import React from "react"
import Colors from "../helpers/Colors"
import { Col, Modal, ModalBody } from "reactstrap"
import { Lottie } from "."

export default class ToriModal extends React.Component {
  render() {
    return (
      <Modal
        isOpen={this.props.visible}
        toggle={this.props.closeModal ? this.props.closeModal : () => {}}
        className="modal-dialog-centered"
        style={{
          marginTop: this.props.noTori ? 0 : 110,
          marginBottom: 0,
          maxWidth: this.props.fullscreen ? "unset" : "",
        }}
        contentClassName={this.props.fullscreen ? "fullscreen-modal" : ""}
        fade={this.props.fade}
      >
        {this.props.noTori ? null : (
          <Lottie lottie={`Modal${this.props.talking ? "Talking" : ""}`} />
        )}
        <ModalBody
          style={{
            textAlign: "center",
            background: this.props.color ? this.props.color : "inherit",
            borderRadius: "0.6rem",
            backgroundSize: "cover",
            padding: this.props.fullscreen ? 0 : "",
          }}
        >
          <Col
            className={`d-flex flex-column align-items-center ${
              this.props.fullscreen ? "full-height" : ""
            }`}
          >
            {this.props.title && (
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: 21,
                  color: Colors.TEXT,
                  marginBottom: 10,
                  whiteSpace: "pre-line",
                }}
              >
                {this.props.title}
              </span>
            )}
            {this.props.children}
          </Col>
        </ModalBody>
      </Modal>
    )
  }
}

export class HTTPRandomAccessDataSource {
  constructor(url, size) {
    this.url = url
    this.size = size
  }

  static async create(url) {
    const options = {
      method: "HEAD",
    }
    return fetch(url, options).then((response) => {
      const contentLength = response.headers.get("Content-Length")
      if (!response.ok) {
        throw new Error(
          `Server responded with an error ${response.status} ${response.statusText}`
        )
      } else if (!contentLength) {
        throw new Error("Content-Length header missing")
      } else {
        const parsedContentLength = parseInt(contentLength)
        if (isNaN(parsedContentLength)) {
          throw new Error("Invalid Content-Length header")
        } else {
          return new HTTPRandomAccessDataSource(url, parsedContentLength)
        }
      }
    })
  }

  fetchChunk(startOffset, endOffset) {
    const options = {
      headers: {
        Range: `bytes=${startOffset}-${endOffset - 1}`,
      },
    }
    return fetch(this.url, options).then((response) => {
      if (response.ok && response.body) {
        return response.body
      } else {
        throw new Error(
          `Fetch failed: ${response.status} ${response.statusText}`
        )
      }
    })
  }

  getSize() {
    return this.size
  }
}

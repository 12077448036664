import Audios from "./Audios"
import { logError } from "./ErrorHelper"

export default (url, playDelay = 0, callback = () => {}) => {
  try {
    const audio = new Audio(Audios[url] || url)
    let timeoutId = setTimeout(async () => {
      try {
        await audio.play()
      } catch (e) {
        logError(e, 2)
        callback()
      }
    }, playDelay)

    audio.onended = function () {
      callback()
    }

    return {
      timeoutId,
      cancel: () => {
        clearTimeout(timeoutId)
        audio.pause()
        audio.currentTime = 0
        // Notificamos que la reproducción se canceló
        callback()
      },
    }
  } catch (error) {
    logError(error, 2)
    callback()
  }
}

import { PublicationType } from "@colibrio/colibrio-reader-framework/colibrio-core-publication-base"

class ReaderUtils {
  static isEpubReaderPublication(readerPublication) {
    return (
      readerPublication.getSourcePublication().getType() ===
      PublicationType.EPUB
    )
  }

  static isPdfReaderPublication(readerPublication) {
    return (
      readerPublication.getSourcePublication().getType() === PublicationType.PDF
    )
  }

  static isWpAudiobookReaderPublication(readerPublication) {
    return (
      readerPublication.getSourcePublication().getType() === PublicationType.WP
    )
  }

  static isHighlightData(data) {
    return data.hasOwnProperty("id") && data["id"].startsWith("IHighlightData")
  }

  static isEbookData(data) {
    return data.hasOwnProperty("id") && data["id"].startsWith("IEbookData")
  }

  static isBookmarkData(data) {
    return data.hasOwnProperty("id") && data["id"].startsWith("IBookmarkData")
  }

  static filterLocalStorageItemsOnTypeName(typeName) {
    const resultList = []

    for (let i = 0; i < localStorage.length; i++) {
      let itemKey = localStorage.key(i)
      if (itemKey && itemKey.startsWith(typeName)) {
        const itemValue = localStorage.getItem(itemKey)
        if (itemValue) {
          const dataItem = JSON.parse(itemValue)
          resultList.push(dataItem)
        }
      }
    }

    return resultList
  }
}

export default ReaderUtils

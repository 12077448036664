import React from "react"

const rocket = require("../assets/img/loginBackground/Rocket.png")
const planets = require("../assets/img/loginBackground/Planets.png")
const space = require("../assets/img/loginBackground/Space.png")

const Posibilities = [
  {
    backgrounds: [rocket.default ?? rocket],
    lotties: ["/lotties/loginTori.json"],
  },
  {
    backgrounds: [planets.default ?? planets],
    lotties: ["/lotties/loginDiego.json"],
  },
  {
    backgrounds: [space.default ?? space],
    lotties: ["/lotties/loginSam.json"],
  },
]

export default class LoginImage extends React.Component {
  constructor(props) {
    super(props)
    const choice = Posibilities[Math.floor(Math.random() * Posibilities.length)]
    this.background =
      choice.backgrounds[Math.floor(Math.random() * choice.backgrounds.length)]
    this.lottie =
      choice.lotties[Math.floor(Math.random() * choice.lotties.length)]
  }

  render() {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundSize: "cover",
          backgroundImage: `url("${this.background}")`,
        }}
      >
        <lottie-player
          src={this.lottie}
          style={{ height: "100vh", alignSelf: "center" }}
          loop
          autoplay
        />
      </div>
    )
  }
}

import React from "react"
import Colors from "../helpers/Colors"
import SideModal from "./SideModal"
import { answersScheme } from "../helpers/Constants"
import Button from "./Button"
import { lang } from "../helpers/Dictionary"
import { Volume2 } from "react-feather"
import { textToSpeechPolly } from "../network/providers/booksProvider"
import { logError } from "../helpers/ErrorHelper"
import PlayAudio from "../helpers/PlayAudio"

export function QAFormat({
  questions,
  answers,
  correctAnswers,
  answerStatus,
  updateAnswerIndex,
  answerIndex,
  answerQuestion,
  eventId,
  skipQuestions,
  noPolly,
}) {
  const [speakingItem, setSpeakingItem] = React.useState(null) // null | "q" | answerIndex
  const [speaking, setSpeaking] = React.useState(false)

  const textToSpeech = (item, text, qaId) => {
    if (!speaking) {
      setSpeaking(true)
      setSpeakingItem(item)
      textToSpeechPolly(
        text,
        qaId,
        item === "q",
        item !== null && item !== "q" ? item : undefined
      )
        .then((url) => {
          PlayAudio(url, 0, () => setSpeaking(false))
        })
        .catch((e) => {
          logError(e, 1)
          setSpeaking(false)
        })
    }
  }

  return questions ? (
    <div
      style={{
        backgroundColor: "#fff",
        width: "35vw",
        height: "auto",
        maxHeight: "100vh",
        minHeight: "100vh",
        position: "absolute",
        right: 0,
        top: 0,
        overflow: "hidden",
        overflowY: "auto",
        borderTopLeftRadius: 15,
        borderBottomLeftRadius: 15,
        padding: 20,
      }}
    >
      <div className="d-flex justify-content-center m-1">
        {questions.map((e, i) => (
          <div
            key={e.id || i}
            style={{
              width: 10,
              height: 10,
              borderRadius: 100,
              margin: 5,
              backgroundColor:
                answers.length === i
                  ? Colors.PRIMARY
                  : correctAnswers[i] !== undefined
                  ? correctAnswers[i]
                    ? Colors.GREEN
                    : Colors.RED
                  : Colors.CAPTION_GRAY,
            }}
          />
        ))}
      </div>
      {questions.length > answers.length ? (
        <>
          <div className="d-flex flex-column px-2 mx-1">
            <span
              className="mb-1"
              style={{
                fontWeight: "bold",
                fontSize: 16,
                color: Colors.TEXT,
                textAlign: "center",
              }}
            >
              {questions[answers.length].question.question}
              {!noPolly ? (
                <Volume2
                  color={
                    Colors[
                      speaking && speakingItem === "q" ? "RED" : "LIGHTBLUE"
                    ]
                  }
                  size={30}
                  style={{ margin: 10, cursor: "pointer" }}
                  onClick={() =>
                    textToSpeech(
                      "q",
                      questions[answers.length].question.question,
                      questions[answers.length].question.id
                    )
                  }
                />
              ) : null}
            </span>
            {questions[answers.length].answers.map((answer, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    if (answerStatus === undefined) {
                      updateAnswerIndex(index)
                    }
                  }}
                  className="d-flex flex-row my-1 p-2 align-items-center w-100 justify-content-between"
                  style={{
                    border: "1px solid #A0DAFF",
                    borderRadius: 13,
                    backgroundColor:
                      answerIndex === index
                        ? answerStatus === undefined
                          ? `rgb(53, 177, 255)`
                          : answerStatus
                          ? Colors.GREEN
                          : Colors.RED
                        : answerStatus !== undefined &&
                          questions[answers.length].question.correct === index
                        ? Colors.GREEN
                        : "#fff",
                    cursor: answerStatus === undefined ? "pointer" : "default",
                  }}
                >
                  <div
                    className="d-flex flex-row align-items-center"
                    style={{ flex: 1 }}
                  >
                    <div
                      className="d-flex flex-row justify-content-center align-items-center align-self-center mr-2 p-1"
                      style={{
                        borderRadius: 100,
                        backgroundColor: `rgba(${
                          answerIndex === index ||
                          (answerStatus !== undefined &&
                            questions[answers.length].question.correct ===
                              index)
                            ? "255,255,255"
                            : "53, 177, 255"
                        }, 0.5)`,
                        height: 32,
                        width: 32,
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: 14,
                          color:
                            answerStatus === undefined
                              ? `rgb(53, 177, 255)`
                              : answerIndex !== index
                              ? questions[answers.length].question.correct ===
                                index
                                ? Colors.GREEN
                                : `rgb(53, 177, 255)`
                              : answerStatus
                              ? Colors.GREEN
                              : Colors.RED,
                        }}
                      >
                        {answersScheme[index]?.letter || ""}
                      </span>
                    </div>
                    <span
                      style={{
                        textAlign: "left",
                        marginRight: "10%",
                        fontSize: 12,
                        color: answerIndex === index ? "#fff" : Colors.TEXT,
                      }}
                    >
                      {answer.answer}
                    </span>
                  </div>
                  {!noPolly ? (
                    <Volume2
                      color={
                        Colors[
                          speaking && speakingItem === index
                            ? "RED"
                            : answerIndex === index
                            ? "WHITE"
                            : "LIGHTBLUE"
                        ]
                      }
                      size={20}
                      style={{ margin: 10, cursor: "pointer" }}
                      onClick={() =>
                        textToSpeech(
                          index,
                          answer.answer,
                          questions[answers.length].question.id
                        )
                      }
                    />
                  ) : null}
                </div>
              )
            })}
            <Button
              text={
                answerStatus === undefined
                  ? lang("answer")
                  : answers.length + 1 === questions.length
                  ? lang("finish")
                  : lang("next")
              }
              onClick={() => answerQuestion()}
              disabled={answerIndex === ""}
            />
            {!eventId && skipQuestions ? (
              <span
                className="m-3 cursor-pointer"
                style={{
                  fontSize: "0.85rem",
                  textAlign: "center",
                  color: Colors.ACHIEVEMENTS_GRAY,
                }}
                onClick={() => skipQuestions()}
              >
                {lang("skip_questions")}
              </span>
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  ) : null
}

export function QuestionModal({ visible, close, ...rest }) {
  return (
    <SideModal visible={visible} close={close}>
      <QAFormat {...rest} />
    </SideModal>
  )
}

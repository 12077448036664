export const Video_tutorial = {
  eng: require("./eng/introToTeeRead.m4v"),
  esp: require("./esp/introToTeeRead.m4v"),
}

export const Video_beforeAssessment = {
  eng: require("./eng/beforeAssessment.mp4"),
  esp: require("./esp/beforeAssessment.m4v"),
}

export const Video_6missions = {
  eng: require("./eng/6missions.mp4"),
  esp: require("./esp/6missions.m4v"),
}

export const Video_tutorialBonus = {
  eng: require("./eng/tutorialBonus.m4v"),
  esp: require("./esp/tutorialBonus.m4v"),
}

export const Video_tutorialDecoding = {
  eng: require("./eng/tutorialDecoding.mp4"),
  esp: require("./esp/tutorialDecoding.m4v"),
}

export const Video_tutorialDecodingLetterSound = {
  eng: require("./eng/tutorialDecodingLetterSound.m4v"),
  esp: require("./esp/tutorialDecodingLetterSound.m4v"),
}

export const Video_tutorialDecodingLetterName = {
  eng: require("./eng/tutorialDecodingLetterName.m4v"),
  esp: require("./esp/tutorialDecodingLetterName.m4v"),
}

export const Video_tutorialVisual = {
  eng: require("./eng/tutorialVisual.mp4"),
  esp: require("./esp/tutorialVisual.m4v"),
}

export const Video_tutorialPA = {
  eng: require("./eng/tutorialPA.m4v"),
  esp: require("./esp/tutorialPA.m4v"),
}

export const Video_tutorialAuditory = {
  eng: require("./eng/tutorialAuditory.m4v"),
  esp: require("./esp/tutorialAuditory.m4v"),
}

export const Video_tutorialSentences = {
  eng: require("./eng/tutorialSentences.m4v"),
  esp: require("./esp/tutorialSentences.m4v"),
}

import axios from "axios"
import { API_URL } from "../../config"
import {
  // getOrUpdateSessionDeviceToken,
  getToken,
} from "../../helpers/AuthHelper"
import Bugsnag from "@bugsnag/js"

const provider = axios.create({ baseURL: API_URL })
const token = getToken()
if (token) {
  provider.defaults.headers.common["Authorization"] = `Bearer ${token}`
} else {
  delete provider.defaults.headers.common["Authorization"]
}

provider.interceptors.request.use(
  function (config) {
    if (config.data instanceof FormData) {
      config.headers.common["Content-Type"] = "multipart/form-data"
    }
    // const sessionDeviceId = getOrUpdateSessionDeviceToken()
    // config.headers.common["x-session-device"] = sessionDeviceId
    // console.log("x-session-device", sessionDeviceId)

    // console.log('Request: ', config)
    Bugsnag.leaveBreadcrumb("EP Request", {
      method: config.method,
      baseURL: config.baseURL,
      url: config.url,
      data: config.data,
    })
    return config
  },
  function (error) {
    // console.log('Request error: ', error)
    return Promise.reject(error)
  }
)

provider.interceptors.response.use(
  function (response) {
    // console.log("Response: ", response)
    Bugsnag.leaveBreadcrumb("EP Response", {
      data: response.data.data,
    })
    return response.data.data
  },
  function (error) {
    // console.log('Response error: ', error)
    return Promise.reject(error)
  }
)

export default provider

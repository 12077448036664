import React from "react";
import Colors from "../helpers/Colors";
import PlayAudio from "../helpers/PlayAudio";

export default class FloatingBtn extends React.Component {
  onClick = () => {
    if (this.props.onClick) {
      PlayAudio('Drop');
      this.props.onClick();
    }
  }
  render() {
    return (
      <div style={{ height: 30 }}>
        <div onClick={this.onClick} style={{
          position: 'fixed',
          bottom: 10,
          right: 10,
          zIndex: 4,
          width: 60,
          height: 60,
          borderRadius: 100,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: Colors.LIGHTBLUE,
          cursor: this.props.onClick && 'pointer',
          boxShadow: "1px 1px 11px 0px rgba(0,0,0,0.31)"
        }}>
          {this.props.icon}
        </div>
        <div style={{
          position: 'fixed',
          bottom: 70,
          right: 10,
          zIndex: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }} onClick={() => { }}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
import React from "react"
import { Card as RSCard, CardBody, Spinner } from "reactstrap"

export default function Card({
  icon,
  title,
  data,
  subtitle,
  loading,
  onClick,
}) {
  return (
    <RSCard
      className={`m-1 d-flex ${onClick ? "scalableHover" : ""}`}
      style={{
        boxShadow: "1px 1px 11px 0px rgba(0,0,0,0.25)",
        flex: 1,
        cursor: onClick ? "pointer" : "default",
      }}
      onClick={onClick}
    >
      <CardBody>
        <h5>
          {icon}
          {icon ? " " : ""}
          {title}
        </h5>
        {loading ? (
          <Spinner color="primary" style={{ height: "1rem", width: "1rem" }} />
        ) : (
          <div>
            <p className="m-0">{data}</p>
            {subtitle}
          </div>
        )}
      </CardBody>
    </RSCard>
  )
}

const Colors = {
  PRIMARY: "#34B0FF",
  BOOB: "#b00b",
  RED: "#FF5764",
  ORANGE: "#FFA134",
  DARK_ORANGE: "#FF7658",
  ORANGE_SHADOW: "#9E2A10",
  YELLOW: "#FFE34F",
  LIGHTBLUE: "#35B1FF",
  LIGHTBLUEARGENTINA: "#9DD5F8",
  BLUE: "#1035F1",
  LILA: "#6457FF",
  PURPLE: "#5864FF",
  GREEN: "#40D78F",
  DARK_GREEN: "#05b05e",
  GREEN_SHADOW: "#438164",
  TEXT: "#333333",
  CAPTION_GRAY: "#686868",
  SEPARATOR: "#F1F1F1",
  ACHIEVEMENTS_GRAY: "#ABABAB",
  LIGHTGRAY: "#DDDDDD",
  WHITE: "#FFFFFF",
  PURPLE_BUTTON: "#57219B",
  PINK_BUTTON: "#FF4D8A",
  GENIALLY_WHITE: "#FAF4EC",
}

export default Colors

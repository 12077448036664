import { ReadableStreamResourceResponse } from "@colibrio/colibrio-reader-framework/colibrio-core-io-base"
import { URLUtils } from "@colibrio/colibrio-reader-framework/colibrio-core-url"

export class HttpResourceProvider {
  /**
   * Creates a new instance that can serve the contents for a single publication.
   *
   * @param _baseUrl - The URL to the root of the EPUB, like: `http://app.test/a-book/` (the ending slash is important!)
   */
  constructor(_baseUrl) {
    this._baseUrl = _baseUrl
  }

  acceptsUrl(url) {
    url = this.normalizeUrl(url)
    return URLUtils.isSubpath(url, this._baseUrl)
  }

  async fetch(url, options = undefined) {
    url = this.normalizeUrl(url)

    if (!this.acceptsUrl(url)) {
      throw new Error(`HttpResourceProvider: Rejected request of URL: ${url} `)
    }

    const headers = new Headers()

    if (options) {
      if (options.accept) {
        headers.set("Accept", options.accept.join(", "))
      }
      if (options.range) {
        const range = options.range
        headers.set("Range", `bytes=${range.start}-${range.end || ""}`)
      }
    }

    const requestUrl = url.toString()
    const abortController = new AbortController()
    const response = await fetch(requestUrl, {
      headers: headers,
      signal: abortController.signal,
    })

    if (!response.ok) {
      throw new Error(
        `HttpResourceProvider.fetch failed: ${response.status} ${response.statusText}`
      )
    }
    if (!response.body) {
      throw new Error(`HttpResourceProvider.fetch failed: Body is empty`)
    }
    if (options?.range && response.status !== 206) {
      throw new Error(
        `HttpResourceProvider.fetch failed: Range request failed, server did not response with 206 Partial Content`
      )
    }

    const metadata = this.createMetadataFromResponse(response, requestUrl)

    return new ReadableStreamResourceResponse(metadata, response.body, () =>
      abortController.abort()
    )
  }

  async fetchMetadata(url, options = undefined) {
    url = this.normalizeUrl(url)

    if (!this.acceptsUrl(url)) {
      throw new Error(`HttpResourceProvider: Rejected request of URL: ${url} `)
    }

    const headers = new Headers()

    if (options) {
      if (options.accept) {
        headers.set("Accept", options.accept.join(", "))
      }
    }
    const requestUrl = url.toString()
    const response = await fetch(requestUrl, {
      headers: headers,
      method: "HEAD",
    })
    if (!response.ok) {
      throw new Error(
        `HttpResourceProvider.fetch failed: ${response.status} ${response.statusText}`
      )
    }
    return this.createMetadataFromResponse(response, requestUrl)
  }

  getBaseUrl() {
    return this._baseUrl
  }

  getResourceManifest() {
    return null
  }

  isUrlSupportedByBrowser(_url, _options = undefined) {
    // Not fully tested yet, safest to use false here for now.
    return true
  }

  createMetadataFromResponse(response, requestUrl) {
    const headers = response.headers
    const acceptsRanges = headers.get("Accept-Ranges")?.includes("bytes")
    const contentTypeRaw = headers.get("Content-Type")
    const mediaType = contentTypeRaw
      ? this.extractMediaType(contentTypeRaw)
      : undefined
    const contentRangeRaw = headers.get("Content-Range")
    const contentLengthRaw = headers.get("Content-Length")

    let size
    if (contentRangeRaw) {
      size = this.extractSizeFromContentRange(contentRangeRaw)
    } else if (contentLengthRaw) {
      size = parseInt(contentLengthRaw, 10)
    }

    return {
      acceptsRanges: acceptsRanges,
      mediaType: mediaType,
      size: typeof size === "number" && !isNaN(size) ? size : undefined,
      url: response.url || requestUrl,
    }
  }

  extractMediaType(contentTypeRaw) {
    const parts = contentTypeRaw.split(";")
    if (parts.length > 0) {
      const mediaType = parts[0].trim()
      if (/^[a-z-+]+\/[a-z-+]+$/i.test(mediaType)) {
        return mediaType
      }
    }
    return
  }

  extractSizeFromContentRange(contentRangeRaw) {
    contentRangeRaw = contentRangeRaw.trim()
    if (contentRangeRaw.startsWith("bytes")) {
      const parts = contentRangeRaw.split("/")
      if (parts.length > 0) {
        return parseInt(parts[parts.length - 1], 10)
      }
    }
    return
  }

  normalizeUrl(url) {
    if (typeof url === "string") {
      url = new URL(url, this._baseUrl)
      URLUtils.normalizePathnamePercentEncoding(url)
    }
    return url
  }
}

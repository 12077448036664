import ReaderUtils from "../utils/ReaderUtils"

export class BookmarkDataStore {
  _dataType = "IBookmarkData"

  fetchBookmarkDataById(id) {
    const storeItem = localStorage.getItem(id)
    let data

    if (storeItem) {
      data = JSON.parse(storeItem)
    }

    return Promise.resolve(data)
  }

  addBookmarkData(data) {
    const uid = `${this._dataType}_${Date.now()}`
    data.id = uid
    localStorage.setItem(uid, JSON.stringify(data))
    return Promise.resolve(data)
  }

  deleteBookmarkDataById(id) {
    let highlightItem = localStorage.getItem(id)
    if (highlightItem) {
      localStorage.removeItem(id)
    } else {
      console.warn(
        `HighlightDataStore.deleteBookmarkDataById, no BookmarkData with id ${id} was found.`
      )
    }
    return Promise.resolve()
  }

  deleteBookmarkDataByEbookId(id) {
    let data = ReaderUtils.filterLocalStorageItemsOnTypeName(this._dataType)
    data.forEach((item) => {
      if (item.ebookId === id && item.id) {
        window.localStorage.removeItem(item.id)
      }
    })
    return Promise.resolve()
  }

  fetchBookmarkDataByEbookId(id) {
    let data = ReaderUtils.filterLocalStorageItemsOnTypeName(this._dataType)
    data = data.filter((item) => {
      return item.ebookId === id
    })
    return Promise.resolve(data.length > 0 ? data : [])
  }
}

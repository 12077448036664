import React from "react";

const Icons = {
  ShirtBlue: require("../assets/img/icons/ShirtBlue.png"),
  ShirtWhite: require("../assets/img/icons/ShirtWhite.png"),
  ToriWhite: require("../assets/img/icons/ToriWhite.png"),
  ToriBlue: require("../assets/img/icons/ToriBlue.png"),
};

export default class CustomIcons extends React.Component {
  render() {
    return (
      <img
        src={Icons[this.props.icon]}
        style={{ ...{ width: this.props.size }, ...this.props.style }}
        alt="Icon"
      />
    );
  }
}

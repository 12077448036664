export const FirstTimeAnimation = {
  src: "/lotties/koalaJump.json",
  style: { width: 400, height: 400 },
}

export const Modal = {
  src: "/lotties/koalaModal.json",
  style: {
    width: 300,
    height: 300,
    alignSelf: "center",
    position: "absolute",
    top: -143,
  },
}

export const ModalTalking = {
  src: "/lotties/koalaModalTalking.json",
  style: {
    width: 300,
    height: 300,
    alignSelf: "center",
    position: "absolute",
    top: -143,
  },
}

export const Countdown = {
  src: "/lotties/countdown.json",
  style: { width: 400, height: 400 },
}

export const ToriGuitar = {
  src: "/lotties/koalaGuitar.json",
  style: { width: 300, height: 300, alignSelf: "center" },
  loop: true,
}

export const ToriLogin = {
  src: "/lotties/koalaHiNoTalking.json",
  style: { width: 400, height: 400 },
}

export const Coin = {
  src: "/lotties/coin.json",
  style: { width: 40, height: 40 },
  loop: true,
}

export const Highlight = {
  src: "lotties/highlight.json",
  loop: true,
}

export const Chest = {
  src: "lotties/chest.json",
  loop: true,
  style: { width: 400, height: 480 },
}

export const ArrowRight = {
  src: "lotties/ArrowRight.json",
  loop: true,
  style: { width: 110, height: 55 },
}

export const ToriDancing = {
  src: "lotties/koalaDancing.json",
  loop: true,
  style: { width: 400, height: 400 },
}

export const LoadingMessage = {
  src: "lotties/loadingMessage.json",
  loop: true,
  style: { width: 50, margin: "0 auto", filter: "brightness(99999)" },
}

import React, { useEffect, useState } from "react"
import Colors from "../../helpers/Colors"
import { FastForward, Pause, Play, Rewind } from "react-feather"
import "./styles.css"

export const ReadAloudMenu = ({ isMobile }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  useEffect(() => {
    const PlayPauseBtn = document.querySelector("#play-pause-button")
    const callback = (e) => {
      setTimeout(() => {
        setIsPlaying(PlayPauseBtn.dataset.isplaying !== undefined)
      }, 100)
    }
    PlayPauseBtn.addEventListener("click", callback)
    return () => {
      PlayPauseBtn.removeEventListener("click", callback)
    }
  }, [])

  return (
    <div
      id="media-overlay-controls-container"
      className="d-flex flex-row align-items-center justify-content-center p-1"
      style={{
        backgroundColor: Colors.GREEN,
        boxShadow: "-1px 1px 3px 3px rgba(0,0,0,0.1)",
        borderRadius: isMobile ? "100%" : "0.6rem",
        width: isMobile ? 45 : undefined,
        height: isMobile ? 45 : undefined,
        position: isMobile ? "absolute" : undefined,
        bottom: isMobile ? 10 : undefined,
        left: isMobile ? 5 : undefined,
      }}
    >
      <button
        type="button"
        id="previous-segment-button"
        className={`media-overlay-btn ${isMobile ? "d-none" : ""}`}
        disabled
      >
        <Rewind size={20} />
      </button>
      <button
        type="button"
        id="play-pause-button"
        className="media-overlay-btn"
        disabled
      >
        {isPlaying ? <Pause size={20} /> : <Play size={20} />}
      </button>
      <button
        type="button"
        id="next-segment-button"
        className={`media-overlay-btn ${isMobile ? "d-none" : ""}`}
        disabled
      >
        <FastForward size={20} />
      </button>
      <div id="media-overlay-status" className="d-none"></div>
      <div id="media-overlay-playback-position" className="d-none"></div>
    </div>
  )
}

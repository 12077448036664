import React, { useCallback, useEffect, useRef, useState } from "react"
import { Pause, Play, Volume, Volume1, Volume2, VolumeX } from "react-feather"
import Colors from "../helpers/Colors"
import { formatTime } from "../helpers/DatesHelper"

export const AudioControls = ({ audioUrl, backgroundColor = Colors.GREEN }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const audioRef = useRef(null)

  const progressBarRef = useRef()
  const [timeProgress, setTimeProgress] = useState(0)
  const [duration, setDuration] = useState(0)
  const playAnimationRef = useRef()

  const [volume, setVolume] = useState(60)
  const [muteVolume, setMuteVolume] = useState(false)

  const repeat = useCallback(() => {
    const currentTime = audioRef?.current?.currentTime
    setTimeProgress(currentTime)
    if (progressBarRef?.current) {
      progressBarRef.current.value = currentTime
      progressBarRef.current.style.setProperty(
        "--range-progress",
        `${(progressBarRef.current.value / duration) * 100}%`
      )
    }

    playAnimationRef.current = requestAnimationFrame(repeat)
  }, [audioRef, duration, progressBarRef, setTimeProgress])

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play()
    } else {
      audioRef.current.pause()
    }
    playAnimationRef.current = requestAnimationFrame(repeat)
  }, [isPlaying, audioRef, repeat])

  useEffect(() => {
    if (audioRef) {
      audioRef.current.volume = volume / 100
      audioRef.current.muted = muteVolume
    }
  }, [volume, audioRef, muteVolume])

  const onLoadedMetadata = () => {
    const seconds = audioRef.current.duration
    setDuration(seconds)
    progressBarRef.current.max = seconds
  }

  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev)
  }

  const handleProgressChange = () => {
    audioRef.current.currentTime = progressBarRef.current.value
  }

  const handleStop = () => {
    audioRef.current.currentTime = 0
    setIsPlaying(false)
  }

  const renderAudioTag = () => {
    if (!audioUrl) return null
    return (
      <audio
        ref={(ref) => (audioRef.current = ref)}
        onLoadedMetadata={onLoadedMetadata}
        controlsList="nodownload"
        onEnded={handleStop}
      >
        <source src={audioUrl} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    )
  }

  return (
    <div
      className={`d-flex flex-row align-items-center justify-content-center p-1`}
      style={{ backgroundColor, borderRadius: "0.6rem" }}
    >
      {renderAudioTag()}
      <div className="d-flex flex-row justify-content-center align-items-center pr-1">
        <div
          className="d-flex justify-content-center align-items-center cursor-pointer"
          onClick={togglePlayPause}
        >
          {isPlaying ? (
            <Pause color={"#fff"} size={20} />
          ) : (
            <Play color={"#fff"} size={20} />
          )}
        </div>
      </div>
      <div
        className="d-flex flex-row justify-content-center align-items-center mr-1"
        style={{ height: 10, color: Colors.WHITE }}
      >
        <span>{formatTime(timeProgress)}</span>
        <input
          className="readAlongRangeInput"
          type="range"
          ref={progressBarRef}
          defaultValue="0"
          onChange={handleProgressChange}
          style={{ margin: "0 0.5rem" }}
        />
        <span>{formatTime(duration)}</span>
      </div>
      <div className="hoverHiddenContainer d-flex flex-row justify-content-center align-items-center">
        <div
          className="d-flex justify-content-center align-items-center cursor-pointer position-relative"
          onClick={() => setMuteVolume((prev) => !prev)}
        >
          {muteVolume || volume <= 0 ? (
            <VolumeX color={"#fff"} size={20} />
          ) : volume > 0 && volume <= 5 ? (
            <Volume color={"#fff"} size={20} />
          ) : volume > 5 && volume < 40 ? (
            <Volume1 color={"#fff"} size={20} />
          ) : (
            <Volume2 color={"#fff"} size={20} />
          )}
        </div>
        <input
          className="hoverHidden"
          type="range"
          min={0}
          max={100}
          value={muteVolume ? 0 : volume}
          onChange={(e) => setVolume(e.target.value)}
          style={{
            maxWidth: 50,
            marginLeft: "0.5rem",
          }}
        />
      </div>
    </div>
  )
}

import ReaderUtils from "../utils/ReaderUtils"

export class ReadingPositionDataStore {
  _dataType = "IReadingPositionData"

  addReadingPositionData(data) {
    const uid = `${this._dataType}_${Date.now()}`
    data.id = uid
    // localStorage.setItem(uid, JSON.stringify(data))
    return Promise.resolve(data)
  }

  deleteReadingPositionDataById(id) {
    return Promise.resolve()
  }

  deleteReadingPositionDataByEbookId(id) {
    return Promise.resolve()
  }

  deleteAllReadingPositionDataByEbookId(id) {
    for (let localStorageKey in window.localStorage) {
      if (localStorageKey.startsWith(this._dataType)) {
        let item = JSON.parse(window.localStorage.getItem(localStorageKey))
        if (item.ebookId === id) {
          window.localStorage.removeItem(localStorageKey)
        }
      }
    }

    return Promise.resolve()
  }

  async fetchMostRecentReadingPositionDataByEbookId(id) {
    const mostRecentPosition = (
      await this.fetchReadingPositionDataByEbookId(id)
    ).sort((a, b) => (a.dateCreated < b.dateCreated ? -1 : 1))[0]
    return Promise.resolve(mostRecentPosition)
  }

  fetchReadingPositionDataById(id) {
    return Promise.resolve(undefined)
  }

  fetchReadingPositionDataByEbookId(id) {
    let data = ReaderUtils.filterLocalStorageItemsOnTypeName(this._dataType)
    data = data.filter((item) => {
      return item.ebookId === id
    })
    return Promise.resolve(data.length > 0 ? data : [])
  }
}

import React from "react"
import * as Lotties from "../helpers/Lotties"

export default class Lottie extends React.Component {
  render() {
    let lottie
    if (this.props.webLottie) {
      lottie = { src: this.props.webLottie, loop: true }
    } else {
      lottie = Lotties[this.props.lottie]
    }
    return (
      <lottie-player
        src={lottie.src}
        style={{ ...lottie.style, ...this.props.style }}
        loop={lottie.loop ? true : undefined}
        autoplay
      />
    )
  }
}

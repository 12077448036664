import React from "react"
import { lang } from "../helpers/Dictionary"
import PlayAudio from "../helpers/PlayAudio"
import Colors from "../helpers/Colors"
import { Check, Delete } from "react-feather"

const MIN_VALUE = 1
const MAX_VALUE = 10

export default function AgeProtection({ onValid = () => {} }) {
  const [values, setValues] = React.useState([])
  const [error, setError] = React.useState(false)
  const [data, setData] = React.useState({
    firstValue: undefined,
    secondValue: undefined,
    result: undefined,
  })

  const parseNumbers = () => {
    const rand = () =>
      Math.floor(Math.random() * (MAX_VALUE - MIN_VALUE + 1)) + MIN_VALUE
    const firstValue = rand()
    const secondValue = rand()
    return {
      firstValue,
      secondValue,
      result: firstValue * secondValue,
    }
  }

  React.useEffect(() => {
    setData(parseNumbers())
  }, [])

  const { firstValue, secondValue, result } = data

  const validate = (value) => {
    if (result === value) {
      onValid()
    } else {
      setValues([])
      setError(true)
    }
  }

  const btnPress = (n) => {
    PlayAudio("Drop")
    if (n !== null) {
      setError(false)
      if (n === -1) {
        if (values.length > 0) {
          setValues(values.filter((e, i) => i !== values.length - 1))
        }
      } else {
        if (values.length < `${result}`.length) {
          const newValues = [...values, `${n}`]
          setValues(newValues)
        }
      }
    } else {
      validate(parseInt(values.join("")))
    }
  }

  const renderInputs = () => {
    const inputs = new Array(result ? `${result}`.length : 0).fill(null)
    return (
      <div className="d-flex flex-row justify-content-center align-items-center">
        {inputs.map((_, index) => (
          <div
            key={index}
            className="d-flex justify-content-center align-items-center"
            style={{
              border: `2px solid ${error ? Colors.RED : "black"}`,
              borderRadius: 10,
              width: 50,
              height: 40,
              margin: 12,
            }}
          >
            <span style={{ fontWeight: "bold", color: "#000", fontSize: 20 }}>
              {values[index] || ""}
            </span>
          </div>
        ))}
      </div>
    )
  }

  const renderKeyboard = () => {
    const numbers = [
      [1, 2, 3],
      [4, 5, 6],
      [7, 8, 9],
      [null, 0, -1],
    ]
    const rows = [0, 1, 2, 3]
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          flex: 1,
          maxHeight: "60%",
          width: "fit-content",
          margin: "0 auto",
        }}
      >
        {rows.map((index) => (
          <div
            key={`Row-${index}`}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
              width: "100%",
            }}
          >
            {numbers[index].map((n) => (
              <div
                key={n}
                style={{
                  backgroundColor:
                    n === null || n < 0 ? "transparent" : Colors.LIGHTBLUE,
                  borderRadius: 100,
                  width: 60,
                  height: 60,
                  margin: 12,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => btnPress(n)}
              >
                {n !== null && n < 0 ? (
                  <Delete size={35} color={Colors.LIGHTBLUE} />
                ) : n === null ? (
                  <Check size={45} color={Colors.LIGHTBLUE} />
                ) : (
                  <span
                    style={{
                      fontWeight: "bold",
                      color: Colors.WHITE,
                      fontSize: 20,
                    }}
                  >
                    {n}
                  </span>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    )
  }

  return (
    <section
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "80vh" }}
    >
      <article
        style={{
          width: "50%",
          height: "80%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        <div className="w-100 text-center">
          <h3>{lang("enter_result")}</h3>
          <h2>
            {firstValue} * {secondValue}
          </h2>
        </div>
        {renderInputs()}
        {renderKeyboard()}
      </article>
    </section>
  )
}
